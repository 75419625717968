@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.avatar {
  &__wrapper {
    position: relative;
    @include all-center-flex;
    @include background-image-settings(
      "/assets/hud/header/background-image.png"
    );
    background-size: 100% 100%;
    width: 28vw;
    img {
      border-radius: 50%;
    }
    padding: 5px;
  }

  &__initials {
    height: 26vw;
    color: white;
    @include all-center-flex;
  }

  &__image{
    border-radius: 50%;
  }
}

.hat {
  width: 120px;
  position: absolute;
  top: -30px;
  z-index: 25;
}
