@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.modal {
  @include all-center-flex;
  flex-direction: column;
  position: relative;

  &__text {
    &_white {
      font-size: 1rem;
      text-shadow: $border-text-light-green;
    }
    &_bold {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  &__reward {
    display: flex;
    align-items: center;

    gap: 5px;
    margin-top: 10px;

    &__value {
      font-size: 1.2rem;
      font-weight: 500;
      color: $text-lighten;
    }

    &__container {
      @include all-center-flex;
      margin-top: 10px;
      width: 80%;
      gap: 25px;
    }

    &__wrapper {
      @include all-center-flex;
      gap: 5px;
    }

    &__images {
      @include all-center-flex;
      margin-top: 20px;
      gap: 10px;
      padding-left: 20px;
      width: 100%;
    }
  }

  &__title__up {
    position: absolute;
    padding: 10px;
    padding-bottom: 15px;
    top: 0px;
    width: 65vw;
    left: 50%;
    text-wrap: wrap;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    text-shadow: -1.5px -1.5px 0 $border-text-color-gold,
      1.5px -1.5px 0 $border-text-color-gold,
      -1.5px 1.5px 0 $border-text-color-gold,
      1.5px 1.5px 0 $border-text-color-gold;
    @include background-image-settings(
      "/assets/popup/popup-background-title.png"
    );
    background-size: 100% 100%;
  }

  &__title {
    color: $text-lighten;
    font-weight: 700;
    text-align: center;
    &__value {
      color: white;
      font-weight: 700;
      font-size: 1.2rem;
      text-shadow: -1.5px -1.5px 0 $border-text-color-gold,
        1.5px -1.5px 0 $border-text-color-gold,
        -1.5px 1.5px 0 $border-text-color-gold,
        1.5px 1.5px 0 $border-text-color-gold;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    color: $text-lighten;
    text-align: center;
    align-items: center;
    line-height: 0.9rem;
  }

  &__bonus {
    &__wrapper {
      display: flex;
      gap: 10px;
      height: 100px;
      align-items: center;
    }
  }

  &__close {
    position: absolute;
    transform: translate(-50%, -50%);
    right: -10px;
    top: 40px;
    z-index: 10;
    width: 32px;
  }

  &__content {
    min-width: 90vw;
    @include all-center-flex;
    @include background-image-settings("/assets/popup/background-popup.png");
    flex-direction: column;
    padding: 30px 35px 40px 35px;
    color: $text-lighten;
    font-weight: 500;
    font-size: 0.8rem;
    background-size: 100% 100%;
    border-radius: 8px;
    text-align: left;
  }

  &__wrapper {
    &__text {
      @include all-center-flex;
    }
  }

  &__image{
    margin-bottom: 3px;
  }

  &__actions {
    display: flex;
    margin-top: 5px;
    height: 50px;
    justify-content: space-around;

    &_large {
      padding: 10px 20px;
    }

    &_small {
      padding: 10px 20px;
    }

    &_xxl {
      padding: 30px 40px;
    }

    button {
      cursor: pointer;
    }
    &__inner {
      @include all-center-flex;
      gap: 5px;
    }
  }

  &__warning {
    color: #d61723;
  }

  &__timer {
    margin: 10px 0px;
    border-radius: 8px;
    padding: 10px 5px;
    border: 3px solid rgba(134, 24, 31, 1);
    background: linear-gradient(180deg, #99565e 0%, #8e453f 100%);
    display: flex;
    align-items: center;
    height: 20px;

    p {
      color: rgba(255, 236, 215, 1);
      font-weight: 700;
    }
    img {
      transform: translateX(-10px);
    }
  }

  &__actions {
    @include all-center-flex;
    flex-direction: column;

    &__cross {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 20%;
      height: 10px;
      left: 50%;
      z-index: 20;
    }

    &__price {
      color: white;
      font-size: 1.5rem;
      font-weight: 700;
      text-shadow: $border-text-light-green;

      &_old {
        opacity: 1;
        color: white;
        z-index: 2;
        font-size: 0.83rem;
        font-weight: 700;
        text-shadow: $border-text-light-green;
      }
    }

    &__bonus {
      position: absolute;
      transform: translate(-50%, -50%);
      transform: rotate(-25deg);
      top: -10vw;
      right: -15vw;
      z-index: 15;
    }
  }
}

.red {
  text-shadow: $border-text-light-red;
}

.w_80 {
  width: 80%;
}

.bonus {
  &__card {
    position: relative;
    @include all-center-flex;
    width: 60px;
    height: 60px;
  }

  &__text {
    color: white;
    font-weight: 700;
    text-shadow: $border-text-gold;
    font-size: 1.4rem;
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: -20px;
    right: -25px;
  }
}

.small {
  margin-top: -5px;
  font-size: 1rem;
  padding-bottom: 5px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_10 {
  margin-top: 10px;
}

.mb_10{
  padding-bottom: 10px;
}
