$black-color: #000;
$disable-quest-color: rgba(18, 6, 6, 0.35);
$text-darken: #270709;
$text-lighten: #59060b;
$border-color: #af4241;
$border-color-darken: #af4214;
$color-ton: #47c9ff;
$border-light-red: rgba(170, 38, 38, 1);
$border-light-green: #236323;
$color-premium: #fdd835;
$color-orange: #e36d00;
$color-bg-light-options: #bd5725;
$color-text-lang: #ffbb9e;
$color-bg-options: #8d241d;
$color-gold: #ffda57;
$background-light: #eac191;
$border-text-color-silver: #14314b;
$border-ton: #2b3b72;
$border-text-color-gold: #59060b;
$border-btn-disabled: #282941;
$color-text-btn-disabled: #c5cfde;
$color-text-green: #90df25;
$btn-background-enabled-beffore: #075434;
$btn-background-enabled-color: #1d8d29;

$linear-gradient-blue: linear-gradient(180deg, #5793ca 1000%, #405ec7 100%);
$linear-gradient-primary: linear-gradient(180deg, #cfa573 0%, #d19a58 100%);
$linear-gradient-secondary: linear-gradient(
  180deg,
  #ee801a 0%,
  #fac333 50%,
  #ee801a 100%
);
$linear-gradient-dark-text: radial-gradient(
  109.47% 58.67% at 50% 50%,
  #681c0b 0%,
  #c95f28 100%
);
$radial-gradient-blue: radial-gradient(
  110.97% 50% at 50% 50%,
  #686c78 0%,
  #8199aa 100%
);
$radial-gradient-btn-active: radial-gradient(
  68.77% 50% at 50% 50%,
  #257a37 0%,
  #7cbd1f 100%
);

$box-shadow-primary: 0px 3px 3px 0px rgba(130, 59, 7, 0.25) inset;
$box-shadow-quest: inset 0px 3px 3px 0px rgba(130, 59, 7, 0.25);
$box-shadow-light: inset 1px 1px 0px 0px rgba(255, 218, 122, 1);

$border-btn-active: 2px solid rgba(7, 84, 52, 1);
$border-btn-text-disabled: -1px -1px 0 $border-btn-disabled,
  1px -1px 0 $border-btn-disabled, -1px 1px 0 $border-btn-disabled,
  1px 1px 0 $border-btn-disabled;
$border-blue: 2px solid $border-btn-disabled;
$border-primary: 2px solid rgba(175, 66, 20, 0.2);
$border-darken: 2px solid $border-color-darken;
$border-avatar: 2px solid $text-lighten;
$border-text-silver: -1px -1px 0 $border-text-color-silver,
  1px -1px 0 $border-text-color-silver, -1px 1px 0 $border-text-color-silver,
  1px 1px 0 $border-text-color-silver;

$border-text-gold: -1px -1px 0 $border-text-color-gold,
  1px -1px 0 $border-text-color-gold, -1px 1px 0 $border-text-color-gold,
  1px 1px 0 $border-text-color-gold;

$border-text-light-red: -1px -1px 0 $border-light-red,
  1px -1px 0 $border-light-red, -1px 1px 0 $border-light-red,
  1px 1px 0 $border-light-red;
$border-text-light-green: -1px -1px 0 $border-light-green,
  1px -1px 0 $border-light-green, -1px 1px 0 $border-light-green,
  1px 1px 0 $border-light-green;

$border-text-light-green-2x: -2px -2px 0 $border-light-green,
  2px -2px 0 $border-light-green, -2px 2px 0 $border-light-green,
  2px 2px 0 $border-light-green;
$border-small-text-gold: -0.5px -0.5px 0 #59060b, 0.5px -0.5px 0 #59060b,
  1px 1px 1px #59060b, 0.5px 0.5px 0 #59060b;

$border-text-gold-2x: -3px -3px 0 $border-text-color-gold,
  3px -3px 0 $border-text-color-gold, -3px 3px 0 $border-text-color-gold,
  3px 3px 0 $border-text-color-gold;

$border-text-premium: -3px -3px 0 $color-premium, 3px -3px 0 $color-premium,
  -3px 3px 0 $color-premium, 3px 3px 0 $color-premium;

$border-text-orange: -1px -1px 0 $color-orange, 1px -1px 0 $color-orange,
  -1px 1px 0 $color-orange, 1px 1px 0 $color-orange;

$border-text-green: -1px 1px 0 $btn-background-enabled-beffore,
  1px -1px 0 $btn-background-enabled-beffore,
  -1px 1px 0 $btn-background-enabled-beffore,
  1px 1px 0 $btn-background-enabled-beffore;

$border-text-ton: -1px 1px 0 $border-ton, 1px -1px 0 $border-ton,
  -1px 1px 0 $border-ton, 1px 1px 0 $border-ton;

$border-test-cost: -1px -1px 0 $text-lighten, 1px -1px 0 $text-lighten,
  -1px 1px 0 $text-lighten, 1px 1px 0 $text-lighten;
