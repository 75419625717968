@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  top: -20%;
  left: 42%;
  display: flex;
  align-items: center;
  width: 100%;
}
