@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.wrapper {
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding: 30px 10px;
    top: calc(37px + 1vh);
    @media screen and (min-width: 410px) {
        top: calc(47px + 1vh);
    }
    @media screen and (min-width: 450px) {
        top: calc(55px + 1vh);
    }
    z-index: 10;
    pointer-events: none;
    img {
        cursor: pointer;
    }
    &__left_column {
        display: flex;
        z-index: 99;
        flex-direction: column;
        gap: 8px;
    }
}

.link {
    &__chest {
        position: absolute;
        top: 70px;
    }

    &__wallet {
        position: absolute;
        top:130px;
        border-radius: 50%;
    }

    &__timer {
        position: absolute;
        top: 180px;
        &__time {
            background: linear-gradient(
                261.94deg,
                #ffdfac 18.52%,
                #ffaf69 90.85%
            );
            border-radius: 8px;
            padding: 0px 8px;
            border: 1px solid rgba(89, 6, 11, 1);
            position: absolute;
            transform: translate(-50%, -50%);
            bottom: -27px;
            left: 50%;
            font-size: 0.7rem;
            font-weight: 900;
            color: rgba(89, 6, 11, 1);
        }
    }
}

.chest__quantity {
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: 0px;
    left: 50%;
    font-size: 0.7rem;
    font-weight: 700;
    color: white;
    text-shadow: $border-text-gold;
}

.image {
    pointer-events: all;
    &_circle {
        pointer-events: all;

        border-radius: 50%;
    }
}
