@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.card {
  @include all-center-flex;
  width: 18vw;
  border-radius: 32px;
  height: 18vw;
  background-color: transparent;
  &__initials{
    font-weight: 900;
  }
}
