@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.button {
  @include all-center-flex;
  flex-direction: column;
  z-index: 11;
  position: relative;
  padding: 2px;
  padding-bottom: 6px;
  height: 48px;
  width: 48px;
  @include background-image-settings("/assets/buttons/background.png");
  background-size: 100% 100%;

  &__cost {
    margin-top: -15px;
    text-shadow: $border-test-cost;
  }

  &__quantity {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: -7px;
    font-size: 1rem;
    left: 50%;
    text-shadow: $border-small-text-gold;
  }
}

.wrapper__animation {
  display: block;
  z-index: 15;
  background-color: transparent !important;
  @include all-center-flex;
}

.disabled {
  filter: grayscale(1);
}
