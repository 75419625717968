@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.ads {
  position: absolute;
  z-index: 15;
  @include all-center-flex;
  transform: translate(-50%, -50%);

  z-index: 1;

  &__clickable__zone {
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 2;
  }

  &__chest {
    &__right {
      top: 75vh;
      left: 80vw;
    }
    &__left {
      top: 72vh;
      left: 12vw;
    }
    &__left_down {
      top: 80vh;
      left: 18vw;
    }
  }
}
