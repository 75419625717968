$breakpoints: (
  "sm": 320px,
  "md-sm": 321px,
  "md-lg": 480px,
  "lg-sm": 481px,
  "lg": 680px,
  "xl-sm": 768px,
  "xl-lg": 1024px,
  "xxl-sm": 1025px,
  "xxl": 1280px,
);

@mixin media($name) {
  @if map-get($breakpoints, $name) {
    @if $name == "sm" {
      @media (max-height: map-get($breakpoints, "sm")) {
        @content;
      }
    } @else if $name == "md-sm" {
      @media (min-height: map-get($breakpoints, "md-sm")) and (max-height: map-get($breakpoints, "md-lg")) {
        @content;
      }
    } @else if $name == "lg" {
      @media (min-height: map-get($breakpoints, "lg")) and (max-height: map-get($breakpoints, "xxl")) {
        @content;
      }
    } @else if $name == "lg-sm" {
      @media (min-height: map-get($breakpoints, "lg-sm")) and (max-height: map-get($breakpoints, "xxl")) {
        @content;
      }
    } @else if $name == "xl-sm" {
      @media (min-height: map-get($breakpoints, "xl-sm")) and (max-height: map-get($breakpoints, "xl-lg")) {
        @content;
      }
    } @else if $name == "xxl-sm" {
      @media (min-height: map-get($breakpoints, "xxl-sm")) and (max-height: map-get($breakpoints, "xxl")) {
        @content;
      }
    }
  }
}
