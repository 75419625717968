@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.footer {
  position: absolute;
  bottom: -1.3vw;
  display: flex;
  z-index: 55;
  width: 100%;
  &__nav {
    width: 100%;
    &__list {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }
}
