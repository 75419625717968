@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.shine {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 1;
  left: 50%;
  width: 100%;
  height: 100%;
  @include all-center-flex;
}

@keyframes rotateShine {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
