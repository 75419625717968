@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.modal {
    &__timer {
        margin: 10px 0px;
        margin-top: 20px;
    }

    &__actions {
        height: 40px;
        margin-top: 10px;
        padding: 20px 50px;
    }

    &__text{
        @include all-center-flex;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        padding-top: 10px;
        margin-bottom: -10px
    }
    &__image{
        width: 180px;
        height: 195px;
    }

    &__content{
        width: 180px;
        padding-bottom: 30px;
    }
} 
