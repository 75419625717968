@mixin background-image-settings($image-url) {
  background-image: url($image-url);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

@mixin all-center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@mixin no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin blue-border {
  border-radius: 4px;
  border: 2px solid #405ec7;
}

@mixin blue-border-text {
  text-shadow: -1px -1px 0 #2b3b72, 1px -1px 0 #2b3b72, -1px 1px 0 #2b3b72,
    1px 1px 0 #2b3b72;
}

@mixin card-proto {
  display: flex;
  border-radius: 12px;
  background: $linear-gradient-primary;
  box-shadow: $box-shadow-primary;
}

@mixin darken-card-proto{
  background: linear-gradient(0deg, #591A06 0%, #B04716 100%);
  box-shadow: 0px 8.6px 3.44px 0px rgba(60, 5, 5, 0.25) inset;
}


@mixin anim-click{
  &:focus {
    animation: clickEffect 0.2s ease;
  }
}

@mixin anim-pulse{
  animation: pulse 1.5s infinite;
}

@keyframes clickEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95); 
  }
  100% {
    transform: scale(1); 
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50%{
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}