@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.timer {
  color: white;
  text-shadow: $border-text-gold;
  font-weight: 700;
  &__wrapper {
    pointer-events: none;
    @include all-center-flex;
    z-index: 55;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0%;
    left: 50%;
    flex-direction: column;

    border-radius: 100px / 60px;
    padding: 2px 15px;

    &__disable_space {
      top: 10%;
    }
  }
}

.coins {
  @include all-center-flex;
  gap: 4px;
  z-index: 30;
}

.wrapper {
  @include all-center-flex;
  justify-content: flex-end;
  gap: 5px;
  position: absolute;
  top: -7vw;
  z-index: 150;
  pointer-events: auto;  
  div canvas {
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    min-height: 50px;
    position: absolute;
    img {
      padding: 2px;
    }
    margin-bottom: 40px;
  }
}

