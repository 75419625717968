@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.canvas {
  @include background-image-settings(
    "/assets/screens/menu/island_background.jpg"
  );
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &__text {
    &__info {
      position: absolute;
      z-index: 10;
      top: 110px;
      transform: translate(-50%, -50%);
      left: 53%;
      font-size: 0.8rem;
      animation: pulse infinite 2s linear;
      padding: 4px 0px;
    }
  }
  &__shine {
    position: relative;
    &__wrapper {
      @include all-center-flex;
      gap: 10px;
      width: 180px;
    }
  }
  &__ads {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.025);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
