@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.wrapper {
  @include all-center-flex;
  justify-content: flex-end;
  gap: 5px;
  position: absolute;
  top: -15vw;
  z-index: 55;

  pointer-events: auto;

  & div:nth-child(1) {
    img {
      padding: 0px;
    }
  }

  & div canvas {
    min-height: 50px;
  }


  height: 115px;

  &__tutorial__active {
    & div:nth-child(2) button {
      @include anim-pulse;
    }
  }

  &__tutorial__active__first {
    & div:nth-child(1) button {
      @include anim-pulse;
    }
  }
}
