@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.coins {
  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
  }

  &__key {
    img {
      padding-bottom: 5px;
      translate: 25px 0px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    transform: scale(0.9);
    margin-right: -14%;
    & > *:nth-child(1) {
      margin-right: 3px;
    }
    & > *:nth-child(2) {
      margin-right: 15px;
    }
  }
}

.wrapper__boost {
  position: relative;
}
