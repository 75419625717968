@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.reward {
  display: flex;
  align-items: center;
  gap: 5px;

  &__container {
    width: 100%;
    display: grid;
    transform: translateX(-5px);
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 80%;
  }

  &__wrapper {
    @include all-center-flex;
    gap: 5px;
  }

  &__value {
    font-size: 1.2rem;
    font-weight: 500;
    color: $text-lighten;
  }
}
