@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.card {
  &__content {
    pointer-events: none;
  }
  
  z-index: 54;
  @include all-center-flex;
  flex-direction: column;
  position: absolute;

  &__image {
    height: 150px;
    width: 120px;
  }

  &__fork {
    padding-right: 30px;
  }

}
