@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.card {
  @include all-center-flex;
  flex-direction: column;
  position: absolute;
  &__image {
    width: 22vh;
    object-fit: cover;
    height: auto;
    z-index: 2;

    @include media("xl-sm") {
      width: 20vh;
    }
  }

  &__fork {
    padding-right: 30px;
  }

  &__clickable_zone {
    position: absolute;
    width: 50%;
    height: 50%;
    z-index: 50;
  }
}

.pearl {
  @include media("lg") {
    left: 32vw !important;
  }
  & div div button canvas {
    z-index: 22 !important;
  }
}

.saloon {
  @include media("lg") {
    left: 6vw !important;
  }
}


.fort {
  @include media("lg") {
    left: 58vw !important;
    top: 41vh !important;
  }
  & div div button canvas {
    z-index: 22 !important;
  }
}
