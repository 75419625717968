@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.wrapper {
  @include all-center-flex;
  justify-content: flex-end;
  gap: 5px;
  position: absolute;
  top: -10vw;
  z-index: 105;
  pointer-events: auto;

  button:nth-child(2) {
    img {
      padding: 2px;
    }
    margin-bottom: 40px !important;
  }

  & div:nth-child(2) {
    margin-bottom: 40px !important;
  }

  & div:nth-child(3) canvas {
    margin-bottom: 40px !important;
  }


  div {
    height: 48px;
    z-index: 200;
  }

  & div:nth-child(1) {
    z-index: 200;
    img {
      padding: 0px;
    }
  }

  &__tutorial__active {
    & div:nth-child(2) button {
      @include anim-pulse;
    }
  }

  &__tutorial__active__second {
    & div:nth-child(3) button {
      @include anim-pulse;
    }
  }

  &__tutorial__active__first {
    & div:nth-child(1) button {
      @include anim-pulse;
    }
  }
}
