@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.footer__nav__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  @include background-image-settings(
    "/assets/hud/footer/menu-item-background.png"
  );
  background-size: 100% 100%;
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: 0.3s all linear;
  & a {
    @include all-center-flex;
    flex-direction: column;
    padding: 2px;
  }
  &.active {
    transition: 0.3s all linear;
    @include background-image-settings(
      "/assets/hud/footer/menu-item-active-background.png"
    );
    height: 110px;
    background-size: 100% 100%;
  }

  .item_name {
    font-size: 14px;
    color: #fff;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
      -1px 1px 0 #000;
  }
}
