@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.card {
  @include card-proto;
  @include all-center-flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  min-width: 70px;
  width: 100%;
  height: 85px;
  border: 2px solid $border-text-color-gold;

  &__mark {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0%;
    left: 50%;
  }

  &__name {
    font-weight: 700;
  }
  &__amount {
    font-weight: 700;
  }
  &__large {
    grid-column: span 3;
  }

  &__future {
    opacity: 0.6;
  }

  &__completed {
    border: 2px solid $border-light-green;
  }
}
