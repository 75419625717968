@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.modal {
  &__wrapper {
    position: absolute;
    z-index: 3000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    @include all-center-flex;
    flex-direction: column;
  }
}
