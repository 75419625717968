@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.coin {
    &__wrapper {
      transform: scale(0.85);
      display: flex;
  
      width: 125px;
      height: 5vh;
      margin-left: -30px;
      position: relative;
      justify-content: space-between;
      align-items: center;
      background-size: contain;
    }
  
    &__icon {
      position: relative;
      translate: 15px 0px;
    }
  
    &__icon__add {
      position: absolute;
      transform: translate(-50%, -50%);
      right: -23%;
      top: 50%;
      z-index: 150;
    }
  
    &__value__wrapper {
      @include all-center-flex;
    }
  
    &__value {
      color: white;
      background: linear-gradient(#59060b, #831717);
      border-radius: 33px;
      min-width: 90px;
      padding: 0px 20px;
      padding-top: 2px;
      display: flex;
      align-items: center;
      font-weight: bold;
      justify-content: center;
      text-align: center;
  
      &__boost {
        position: absolute;
        transform: translate(-50%, -50%);
        right: -15%;
        top: 28%;
  
        &__image {
          position: absolute;
          left: 69%;
          top: -18px;
        }
        &__multiplier {
          font-size: 0.8rem;
          font-weight: 600;
          @include all-center-flex;
  
          color: $color-premium;
          text-shadow: $border-text-gold;
          gap: 2px;
          position: absolute;
          left: 51%;
          top: -18px;
        }
        &__time {
          position: absolute;
          left: 48%;
          bottom: -22px;
          color: $color-text-green;
          text-shadow: $border-text-green;
  
          &__long {
            left: 40%;
          }
        }
      }
    }
  }