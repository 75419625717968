@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.text {
  background: $linear-gradient-dark-text;
  border:1px solid $text-lighten;

  border-radius: 8px;
  padding:4px 12px;
  font-weight: 700;
  text-shadow: $border-text-gold;
}
